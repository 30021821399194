import React from 'react';
import AgencyMain from './agency/agency-main';

function AgencyDetails() {
  return (
    <div className="main-content">
      <AgencyMain/>
    </div>
  );
}

export default AgencyDetails;
