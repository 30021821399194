import React from 'react';
import Applicants from './applicants';
import Correspondence from './correspondence';

function Maincontentbottom() {
  return (
    <div className="main-content-bottom">
        <Applicants/>
        <Correspondence/>
    </div>
  );
}

export default Maincontentbottom;
