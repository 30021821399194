import React, { useState } from 'react';
import { FaSearch, FaAngleRight, FaAngleLeft, FaEdit, FaTrash } from 'react-icons/fa';

function Agency() {
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('agency-Details');

    const agencyDetails = [
        { agencyCode: 'EDEX123', agencyName: 'EDEX Academy (NZ) Ltd' }
    ];

    const contactDetails = [
        { type: 'Mobile Phone', name: 'Veronica Samuel', description: '+64123456789' },
        { type: 'Other Phone', name: 'Veronica Samuel', description: '9603673611' },
        { type: 'Contact Email', name: 'Veronica Samuel', description: 'edexhyd@gmail.com' },
        { type: 'Other Email', name: 'Srilatha Kadumuri', description: 'srilatha.edex@gmail.com' },
        { type: 'Other Email', name: 'Veronica Samuel', description: 'prince.edex@gmail.com' }
    ];

    const addressDetails = [
        { type: 'Permanent/Home', address: 'PO Box 15654 NEW LYNN Auckland 0600', mail: 'checkbox' },
        { type: 'Address while studying', address: "12-7-3/1 Lane beside St. Anthony's Church Mettuguda Secunderabad Hyderabad India 500017", mail: 'checkbox' }
    ];

    let items = [];
    switch (activeTab) {
        case 'agency-Details':
            items = agencyDetails;
            break;
        case 'contact-Details':
            items = contactDetails;
            break;
        case 'address-Details':
            items = addressDetails;
            break;
        default:
            break;
    }

    const totalItems = items.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const displayedItems = items.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1); 
    };

    return (
        <div className='details-wrapper'>
            <div className='heading'>
                <h4>Agency</h4>
            </div>
            <div className='table-container'>
                <div className='search-box-container'>
                    <div className="search-box">
                        <input type="text" placeholder="Search..." />
                        <button type="button"><FaSearch /></button>
                    </div>
                </div>
                <div className='toggle-container'>
                    <div className='toggle-tabs'>
                        <ul>
                            <li
                                className={activeTab === 'agency-Details' ? 'active' : ''}
                                onClick={() => handleTabClick('agency-Details')}
                            >
                                Agency Details
                            </li>
                            <li
                                className={activeTab === 'contact-Details' ? 'active' : ''}
                                onClick={() => handleTabClick('contact-Details')}
                            >
                                Contact Details
                            </li>
                            <li
                                className={activeTab === 'address-Details' ? 'active' : ''}
                                onClick={() => handleTabClick('address-Details')}
                            >
                                Address Details
                            </li>
                        </ul>
                    </div>
                    <div className='toggle-data'>
                        <div className='table-responsive'>
                            <table>
                                <thead>
                                    <tr>
                                        {activeTab === 'agency-Details' && (
                                            <>
                                                <th>Agency Code</th>
                                                <th>Agency Name</th>
                                            </>
                                        )}
                                        {activeTab === 'contact-Details' && (
                                            <>
                                                <th>Type</th>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Action</th>
                                            </>
                                        )}
                                        {activeTab === 'address-Details' && (
                                            <>
                                                <th>Type</th>
                                                <th>Address</th>
                                                <th>Action</th>
                                            </>
                                        )}
                                    </tr>
                                </thead>
                                <tbody id="content">
                                    {displayedItems.map((item, index) => (
                                        <tr key={index} className="item">
                                            {activeTab === 'agency-Details' && (
                                                <>
                                                    <td>{item.agencyCode}</td>
                                                    <td>{item.agencyName}</td>
                                                </>
                                            )}
                                            {activeTab === 'contact-Details' && (
                                                <>
                                                    <td>{item.type}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.description}</td>
                                                    <td>
                                                        <button><FaEdit /> Edit</button>
                                                        <button><FaTrash /> Delete</button>
                                                    </td>
                                                </>
                                            )}
                                            {activeTab === 'address-Details' && (
                                                <>
                                                    <td>{item.type}</td>
                                                    <td>{item.address}</td>
                                                    <td>
                                                        <button><FaEdit /> Edit</button>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {activeTab === 'contact-Details' && (
                            <div className='add-btn'>
                                <button className='add-contact-details'>Add Contact Details</button>
                            </div>
                        )}
                        <div className="pagination-container">
                            {totalItems > 0 && (
                                <div className='page-numbers'>
                                    <button className="pagination-button" onClick={handlePrevClick} disabled={currentPage === 1} >
                                        <FaAngleLeft />
                                    </button>
                                    <span className="page-info">
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <button className="pagination-button" onClick={handleNextClick} disabled={currentPage === totalPages} >
                                        <FaAngleRight />
                                    </button>
                                </div>
                            )}
                            <div className='total-items'>
                                <span className="page-info">
                                    Total Items: {totalItems}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Agency;
