import React from 'react';
import Maincontenttop from './main-content-top'
import Maincontentbottom from './main-content-bottom'

function Maincontent() {
  return (
    <div className="main-content">
      <Maincontenttop/>
      <Maincontentbottom/>
    </div>
  );
}

export default Maincontent;
