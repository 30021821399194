import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaInfoCircle, FaDollarSign, FaQuestionCircle, FaSignOutAlt, FaUser } from 'react-icons/fa';
import logo from './images/logo.jpg';

function dynamicCurrentMenuClass(selector, currentPath) {
  const listItems = selector.querySelectorAll('li');

  listItems.forEach((li) => {
    const anchor = li.querySelector('a');
    if (anchor) {
      // Compare the current path directly
      const anchorPath = anchor.getAttribute('href');
      if (anchorPath === currentPath) {
        li.classList.add('current');
      } else {
        li.classList.remove('current');
      }
    }
  });
}

const Header = () => {
  const location = useLocation();

  useEffect(() => {
    const mainNavUL = document.querySelector('.current-menu');
    if (mainNavUL) {
      dynamicCurrentMenuClass(mainNavUL, location.pathname);
    }
  }, [location]);

  return (
    <header>
      <div className='header-wrapper'>
        <div className="logo">
          <div className="logo-box">
            <Link to="/"><img src={logo} alt="Logo" /></Link>
          </div>
        </div>
        <nav>
          <ul className="current-menu">
            <li><Link to="/"><FaHome /> Home</Link></li>
            <li><Link to="/agency-details"><FaInfoCircle /> Agency Details</Link></li>
            <li><Link to="/commission"><FaDollarSign /> Commission</Link></li>
            <li><a href="https://agentonline.aut.ac.nz/Arion.AgentOnline.Host/Home/HelpIndex" target="_blank" rel="noopener noreferrer">
              <FaQuestionCircle /> FAQ</a>
            </li>
            <li><Link to="/log-out"><FaSignOutAlt /> Log Off</Link></li>
            <li><Link to="/profile"><FaUser /> Prince Vijaykumar</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
