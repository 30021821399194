import React, { useState } from 'react';
import { FaSearch, FaAngleRight, FaAngleLeft } from 'react-icons/fa';

function Correspondence() {
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    // Sample data with item objects
    const items = []; // Your items array will go here

    const totalItems = items.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const displayedItems = items.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className='details-wrapper'>
            <div className='heading'>
                <h4>Correspondence</h4>
            </div>
            <div className='table-container'>
                <div className='search-box-container'>
                    <div className="search-box">
                        <input type="text" placeholder="Search..." />
                        <button type="button"><FaSearch /></button>
                    </div>
                </div>
                <div className='toggle-container'>
                    <div className='toggle-data'>
                    <div className='table-responsive'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Date Sent</th>
                                    <th>Read On</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody id="content-2">
                                {totalItems > 0 ? (
                                    displayedItems.map((item, index) => (
                                        <tr key={index} className="item">
                                            <td>{item.description}</td>
                                            <td>{item.dateSent}</td>
                                            <td>{item.readOn}</td>
                                            <td><button>View</button></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="no-items">
                                            There are no items to display
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        </div>
                        {/* Pagination */}
                        
                            <div className="pagination-container">
                            {totalItems > 0 && (
                                <div className='page-numbers'>
                                    <button className="pagination-button" onClick={handlePrevClick} disabled={currentPage === 1}>
                                        <FaAngleLeft />
                                    </button>
                                    <span className="page-info">
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <button className="pagination-button" onClick={handleNextClick} disabled={currentPage === totalPages}>
                                        <FaAngleRight />
                                    </button>
                                </div>
                            )}
                                <div className='total-items'>
                                    <span className="page-info">
                                        Total Items: {totalItems}
                                    </span>
                                </div>
                            </div>
                       
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Correspondence;
