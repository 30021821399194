import React from 'react';
import Agency from '././agency';
import Employees from '././employees';

function AgencyMain() {
  return (
    <div className="agency-main-content-bottom">
        <Agency/>
        <Employees/>
    </div>
  );
}

export default AgencyMain;
