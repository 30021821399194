import React, { useState } from 'react';
import { FaSearch, FaAngleRight, FaEdit, FaAngleLeft } from 'react-icons/fa';

function Employees() {
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    // Sample data with item objects
    const employeesDetails = [
        { firstName: 'Prince', familyName: 'Vijay kumar', position: 'Managing Director', type:'Agency Manager', id:'Edex01', status:'Enabled' },
        { firstName: 'Veronica', familyName: 'Samuel', position: 'CEO', type:'Agency Manager', id:'Edex02', status:'Enabled' },
        { firstName: 'srilatha', familyName: 'kadumuri', position: 'Business Development Manager', type:'Agency Employee', id:'Edex03', status:'Deleted' },
        { firstName: 'Srilatha', familyName: 'k', position: 'Business Development Manager', type:'Agency Employee', id:'Edex04', status:'Enabled' },
    ]; // Your items array will go here

    const totalItems = employeesDetails.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const displayedItems = employeesDetails.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className='details-wrapper'>
            <div className='heading'>
                <h4>Employees</h4>
            </div>
            <div className='table-container'>
                <div className='search-box-container'>
                    <div className="search-box">
                        <input type="text" placeholder="Search..." />
                        <button type="button"><FaSearch /></button>
                    </div>
                </div>
                <div className='toggle-container'>
                    <div className='toggle-data'>
                    <div className='table-responsive'>
                        <table>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Family Name</th>
                                    <th>Position Desc</th>
                                    <th>Type</th>
                                    <th>User Id</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody id="content-2">
                                {totalItems > 0 ? (
                                    displayedItems.map((item, index) => (
                                        <tr key={index} className="item">
                                            <td>{item.firstName}</td>
                                            <td>{item.familyName}</td>
                                            <td>{item.position}</td>
                                            <td>{item.type}</td>
                                            <td>{item.id}</td>
                                            <td>{item.status}</td>
                                            <td>
                                            <button><FaEdit /> Edit</button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="no-items">
                                            There are no items to display
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        </div>
                        <div className='add-btn'>
                                <button className='add-contact-details'>Add Employee</button>
                            </div>
                        {/* Pagination */}
                        
                            <div className="pagination-container">
                            {totalItems > 0 && (
                                <div className='page-numbers'>
                                    <button className="pagination-button" onClick={handlePrevClick} disabled={currentPage === 1}>
                                        <FaAngleLeft />
                                    </button>
                                    <span className="page-info">
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <button className="pagination-button" onClick={handleNextClick} disabled={currentPage === totalPages}>
                                        <FaAngleRight />
                                    </button>
                                </div>
                            )}
                                <div className='total-items'>
                                    <span className="page-info">
                                        Total Items: {totalItems}
                                    </span>
                                </div>
                            </div>
                       
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Employees;
