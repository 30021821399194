import React, { useEffect , useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaEnvelope, FaDollarSign, FaUser } from 'react-icons/fa';

function Maincontenttop() {
  const [isNewApplicantOpen, setIsNewApplicantOpen] = useState(false);
  const [isCorrespondenceOpen, setIsCorrespondenceOpen] = useState(false);

  const handleOpenNewApplicant = () => setIsNewApplicantOpen(true);
  const handleCloseNewApplicant = () => setIsNewApplicantOpen(false);

  const handleOpenCorrespondence = () => setIsCorrespondenceOpen(true);
  const handleCloseCorrespondence = () => setIsCorrespondenceOpen(false);


  function dynamicCurrentMenuClass(selector, currentPath) {
    const listItems = selector.querySelectorAll('li');
    listItems.forEach((li) => {
      const anchor = li.querySelector('a');
      if (anchor && anchor.getAttribute('href') === currentPath) {
        li.classList.add('current');
      } else {
        li.classList.remove('current');
      }
    });
  }
  
    const location = useLocation();
  
    useEffect(() => {
      const mainNavUL = document.querySelector('.current-menu');
      if (mainNavUL) {
        dynamicCurrentMenuClass(mainNavUL, location.pathname);
      }
    }, [location]);

  return (
    <div className="main-content-top">
        <ul className="btn-list">
            <li>
                <a href="#new-applicant" onClick={handleOpenNewApplicant}>
                  <FaUser /> New Applicant
                </a>
            </li>
            <li>
                <a href="#send-correspondence" onClick={handleOpenCorrespondence}>
                  <FaEnvelope /> Send Correspondence
                </a>
            </li>
            <li>
                <Link to="/commission"><FaDollarSign /> Commission</Link>
            </li>
        </ul>

        {/* New Applicant Form Modal */}
        {isNewApplicantOpen && (
            <div className="modal">
                <div className="modal-content">
                <div className="heading">
                    <h4>New Applicant</h4>
                    <span className="close" onClick={handleCloseNewApplicant}>&times;</span>
                    </div>
                    <form>
                        <label>
                            Title:
                            <select className="form-control" id="NameTitle" name="NameTitle" >
                                <option value="None">None</option>
                                <option value="Ass Prof">Ass Prof</option>
                                <option value="Dame">Dame</option>
                                <option value="Dr">Dr</option>
                                <option value="Master">Master</option>
                                <option value="Md">Md</option>
                                <option value="Miss">Miss</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Ms">Ms</option>
                                <option value="Mx">Mx</option>
                                <option value="Prof">Prof</option>
                                <option value="Sir">Sir</option>
                            </select>
                        </label>
                        <label>
                            Gender<span className="important">*</span>:
                            <select className="form-control" id="Gender" name="Gender" >
                                <option value="None">None</option>
                                <option value="Another Gender">Another Gender</option>
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                            </select>
                        </label>
                        <label>
                            First Name<span className="important">*</span>:
                            <input type="text" id="FirstName" name="FirstName" />
                        </label>
                        <label>
                            Family Name<span className="important">*</span>:
                            <input type="text" id="FamilyName" name="FamilyName" />
                        </label>
                        <label>
                            Citizenship<span className="important">*</span>:
                            <input type="text" id="Citizenship" name="Citizenship" />
                        </label>
                        <label>
                            Ethnicity<span className="important">*</span>:
                            <input type="text" id="Ethnicity" name="Ethnicity" />
                        </label>
                        <label>
                            Date Of Birth<span className="important">*</span>:
                            <input type="date" id="DOB" name="DOB" />
                        </label>
                        <label>
                            Country Of Birth<span className="important">*</span>:
                            <input type="text" id="CountryOfBirth" name="CountryOfBirth" />
                        </label>
                        <label>
                            Contact Email (Applicant)<span className="important">*</span>:
                            <input type="email" id="Email" name="Email" />
                        </label>
                        <div className="model-btn">
                            <button type="submit">Save</button>
                            <button onClick={handleCloseNewApplicant} className="close-btn">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        )}

        {/* Send Correspondence Form Modal */}
        {isCorrespondenceOpen && (
            <div className="modal">
                <div className="modal-content">
                <div className="heading">
                    <h4>Send Correspondence</h4>
                    <span className="close" onClick={handleCloseCorrespondence}>&times;</span>
                </div>
                    <form>
                        <label>
                            From Address:
                            <input type="text" id="Address" name="Address" />
                        </label>
                        <label>
                            Subject:
                            <input type="text" id="Subject" name="Subject" />
                        </label>
                        <label>
                            Student Id:
                            <input type="text" id="StudentId" name="StudentId" />
                        </label>
                        <label>
                            Application Id:
                            <input type="text" id="ApplicationId" name="ApplicationId" />
                        </label>
                        <label className="w-100">
                            Message:
                            <textarea id="Message" rows={4} name="Message"></textarea>
                        </label>
                        <div className="model-btn">
                            <button type="submit">Send</button>
                            <button onClick={handleCloseCorrespondence} className="close-btn">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        )}
    </div>
  );
}

export default Maincontenttop;
