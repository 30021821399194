import React, { useState } from 'react';
import { FaSearch, FaAngleRight, FaAngleLeft } from 'react-icons/fa';

function Commission() {
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('eligible-commission');

    const eligibleCommission = [
        { studentId: '1001', studentName: 'John Doe', ioop: 'IOOP123', programme: 'BSc Computer Science', tuitionFee: '$20,000', calcTuitionFee: '$18,000', commRateAmount: '10%', commission: '$1,800' },
        { studentId: '1002', studentName: 'Jane Smith', ioop: 'IOOP456', programme: 'MBA', tuitionFee: '$25,000', calcTuitionFee: '$22,500', commRateAmount: '15%', commission: '$3,375' }
    ];

    const commissionPipeline = [
      { status: 'Pending', studentId: '1001', studentName: 'John Doe', ioop: 'IOOP123', programme: 'BSc Computer Science', tuitionFee: '$20,000', calcTuitionFee: '$18,000', commRateAmount: '10%', commission: 1800, eligibleDate: '2024-09-23', actNo: 'ACT001', reason: 'Pending approval' },
      { status: 'Approved', studentId: '1002', studentName: 'Jane Smith', ioop: 'IOOP456', programme: 'MBA', tuitionFee: '$25,000', calcTuitionFee: '$22,500', commRateAmount: '15%', commission: 3375, eligibleDate: '2024-09-20', actNo: 'ACT002', reason: 'Approved by admin' },
      { status: 'Approved', studentId: '1003', studentName: 'Alice Brown', ioop: 'IOOP789', programme: 'MSc Data Science', tuitionFee: '$30,000', calcTuitionFee: '$27,000', commRateAmount: '12%', commission: 3240, eligibleDate: '2023-08-19', actNo: 'ACT003', reason: 'Approved by admin' },
      { status: 'Pending', studentId: '1004', studentName: 'Bob Green', ioop: 'IOOP321', programme: 'BA History', tuitionFee: '$18,000', calcTuitionFee: '$16,200', commRateAmount: '10%', commission: 1620, eligibleDate: '2023-07-15', actNo: 'ACT004', reason: 'Pending approval' },
      { status: 'Approved', studentId: '1005', studentName: 'Chris White', ioop: 'IOOP654', programme: 'PhD Physics', tuitionFee: '$40,000', calcTuitionFee: '$36,000', commRateAmount: '15%', commission: 5400, eligibleDate: '2024-09-01', actNo: 'ACT005', reason: 'Approved by admin' },
      { status: 'Pending', studentId: '1006', studentName: 'Diana Blue', ioop: 'IOOP987', programme: 'BEng Mechanical Engineering', tuitionFee: '$22,000', calcTuitionFee: '$19,800', commRateAmount: '10%', commission: 1980, eligibleDate: '2022-06-10', actNo: 'ACT006', reason: 'Pending approval' },
      { status: 'Approved', studentId: '1007', studentName: 'Eve Grey', ioop: 'IOOP654', programme: 'MSc Artificial Intelligence', tuitionFee: '$28,000', calcTuitionFee: '$25,200', commRateAmount: '12%', commission: 3024, eligibleDate: '2023-11-05', actNo: 'ACT007', reason: 'Approved by admin' },
      { status: 'Pending', studentId: '1008', studentName: 'Frank Yellow', ioop: 'IOOP147', programme: 'BSc Economics', tuitionFee: '$21,000', calcTuitionFee: '$18,900', commRateAmount: '10%', commission: 1890, eligibleDate: '2022-12-25', actNo: 'ACT008', reason: 'Pending approval' },
      { status: 'Approved', studentId: '1009', studentName: 'Gina Orange', ioop: 'IOOP258', programme: 'MBA', tuitionFee: '$26,000', calcTuitionFee: '$23,400', commRateAmount: '15%', commission: 3510, eligibleDate: '2024-01-30', actNo: 'ACT009', reason: 'Approved by admin' },
      { status: 'Pending', studentId: '1010', studentName: 'Henry Violet', ioop: 'IOOP369', programme: 'BBA', tuitionFee: '$19,000', calcTuitionFee: '$17,100', commRateAmount: '10%', commission: 1710, eligibleDate: '2023-09-11', actNo: 'ACT010', reason: 'Pending approval' },
      { status: 'Approved', studentId: '1011', studentName: 'Ivy Red', ioop: 'IOOP741', programme: 'MSc Biology', tuitionFee: '$29,000', calcTuitionFee: '$26,100', commRateAmount: '12%', commission: 3132, eligibleDate: '2023-10-08', actNo: 'ACT011', reason: 'Approved by admin' },
      { status: 'Approved', studentId: '1012', studentName: 'Jack Black', ioop: 'IOOP852', programme: 'PhD Chemistry', tuitionFee: '$35,000', calcTuitionFee: '$31,500', commRateAmount: '15%', commission: 4725, eligibleDate: '2022-04-15', actNo: 'ACT012', reason: 'Approved by admin' },
      { status: 'Pending', studentId: '1013', studentName: 'Kate White', ioop: 'IOOP963', programme: 'BA Philosophy', tuitionFee: '$17,000', calcTuitionFee: '$15,300', commRateAmount: '10%', commission: 1530, eligibleDate: '2023-02-20', actNo: 'ACT013', reason: 'Pending approval' },
      { status: 'Approved', studentId: '1014', studentName: 'Liam Pink', ioop: 'IOOP159', programme: 'BSc Mathematics', tuitionFee: '$23,000', calcTuitionFee: '$20,700', commRateAmount: '12%', commission: 2484, eligibleDate: '2024-03-17', actNo: 'ACT014', reason: 'Approved by admin' },
      { status: 'Pending', studentId: '1015', studentName: 'Mia Brown', ioop: 'IOOP753', programme: 'MSc Physics', tuitionFee: '$32,000', calcTuitionFee: '$28,800', commRateAmount: '10%', commission: 2880, eligibleDate: '2022-07-29', actNo: 'ACT015', reason: 'Pending approval' }
  ];

    const adjustment = [
        { amount: '$500', reason: 'Fee adjustment', changeDate: '2024-09-15', originalAct: 'ACT003' },
        { amount: '$1,000', reason: 'Discount adjustment', changeDate: '2024-09-10', originalAct: 'ACT004' }
    ];

    let items = [];
    switch (activeTab) {
        case 'eligible-commission':
            items = eligibleCommission;
            break;
        case 'commission-Pipeline':
            items = commissionPipeline;
            break;
        case 'adjustment-details':
            items = adjustment;
            break;
        default:
            break;
    }

    const totalItems = items.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const displayedItems = items.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1);
    };

    return (
        <div className='details-wrapper m-30'>
            <div className='heading'>
                <h4>Commission</h4>
            </div>
            <div className='table-container'>
                <div className='search-box-container'>
                    <div className="search-box">
                        <input type="text" placeholder="Search..." />
                        <button type="button"><FaSearch /></button>
                    </div>
                </div>
                <div className='toggle-container'>
                    <div className='toggle-tabs'>
                        <ul>
                            <li
                                className={activeTab === 'eligible-commission' ? 'active' : ''}
                                onClick={() => handleTabClick('eligible-commission')}
                            >
                                Accept/Reject Commission - Eligible Commission
                            </li>
                            <li
                                className={activeTab === 'commission-Pipeline' ? 'active' : ''}
                                onClick={() => handleTabClick('commission-Pipeline')}
                            >
                                Commission Pipeline
                            </li>
                            <li
                                className={activeTab === 'adjustment-details' ? 'active' : ''}
                                onClick={() => handleTabClick('adjustment-details')}
                            >
                                Adjustment
                            </li>
                        </ul>
                    </div>
                    <div className='toggle-data'>
                        <div className='table-responsive'>
                            <table>
                                <thead>
                                    <tr>
                                        {activeTab === 'eligible-commission' && (
                                            <>
                                                <th>Student ID</th>
                                                <th>Student Name</th>
                                                <th>IOOP</th>
                                                <th>Programme</th>
                                                <th>Tuition Fee</th>
                                                <th>Calc Tuition Fee</th>
                                                <th>Comm Rate/Amount</th>
                                                <th>Commission</th>
                                                <th>Actions</th>
                                            </>
                                        )}
                                        {activeTab === 'commission-Pipeline' && (
                                            <>
                                                <th>Status</th>
                                                <th>Student ID</th>
                                                <th>Student Name</th>
                                                <th>IOOP</th>
                                                <th>Programme</th>
                                                <th>Tuition Fee</th>
                                                <th>Calc Tuition Fee</th>
                                                <th>Comm Rate/Amount</th>
                                                <th>Commission</th>
                                                <th>Eligible Date</th>
                                                <th>ACT No</th>
                                                <th>Reason</th>
                                                <th>Actions</th>
                                            </>
                                        )}
                                        {activeTab === 'adjustment-details' && (
                                            <>
                                                <th>Amount</th>
                                                <th>Reason</th>
                                                <th>Change Date</th>
                                                <th>Original ACT</th>
                                            </>
                                        )}
                                    </tr>
                                </thead>
                                <tbody id="content">
                                    {displayedItems.map((item, index) => (
                                        <tr key={index} className="item">
                                            {activeTab === 'eligible-commission' && (
                                                <>
                                                    <td>{item.studentId}</td>
                                                    <td>{item.studentName}</td>
                                                    <td>{item.ioop}</td>
                                                    <td>{item.programme}</td>
                                                    <td>{item.tuitionFee}</td>
                                                    <td>{item.calcTuitionFee}</td>
                                                    <td>{item.commRateAmount}</td>
                                                    <td>{item.commission}</td>
                                                    <td>
                                                        <button>View</button>
                                                    </td>
                                                </>
                                            )}
                                            {activeTab === 'commission-Pipeline' && (
                                                <>
                                                    <td>{item.status}</td>
                                                    <td>{item.studentId}</td>
                                                    <td>{item.studentName}</td>
                                                    <td>{item.ioop}</td>
                                                    <td>{item.programme}</td>
                                                    <td>{item.tuitionFee}</td>
                                                    <td>{item.calcTuitionFee}</td>
                                                    <td>{item.commRateAmount}</td>
                                                    <td>{item.commission}</td>
                                                    <td>{item.eligibleDate}</td>
                                                    <td>{item.actNo}</td>
                                                    <td>{item.reason}</td>
                                                    <td>
                                                        <button>View</button>
                                                    </td>
                                                </>
                                            )}
                                            {activeTab === 'adjustment-details' && (
                                                <>
                                                    <td>{item.amount}</td>
                                                    <td>{item.reason}</td>
                                                    <td>{item.changeDate}</td>
                                                    <td>{item.originalAct}</td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                       
                        <div className="pagination-container">
                            {totalItems > 0 && (
                                <div className='page-numbers'>
                                    <button className="pagination-button" onClick={handlePrevClick} disabled={currentPage === 1} >
                                        <FaAngleLeft />
                                    </button>
                                    <span className="page-info">
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <button className="pagination-button" onClick={handleNextClick} disabled={currentPage === totalPages} >
                                        <FaAngleRight />
                                    </button>
                                </div>
                            )}
                            <div className='total-items'>
                                <span className="page-info">
                                    Total Items: {totalItems}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Commission;
