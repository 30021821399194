import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './header';
import Maincontent from './main-content';
import Commission from './commission';
import AgencyDetails from './agency-details';
import Footer from './footer';

function Mainwrapper() {

  return (
    <div className="main-wrapper">
        <Header/>
        <Routes>
        <Route path="/" element={<Maincontent />} />
        <Route path="/commission" element={<Commission />} />
        <Route path="/agency-details" element={<AgencyDetails />} />
        {/* Add more routes as needed */}
      </Routes>
        <Footer/>
    </div>
  );
}

export default Mainwrapper;
