import React, { useState } from 'react';
import { FaSearch, FaAngleRight, FaAngleLeft } from 'react-icons/fa';

function Applicants() {
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('my-applicants');

    // Sample data for "My Applicants"
    const myApplicants = [
        { studentId: 'S001', firstName: 'John', familyName: 'Doe', managedBy: 'Alice', status: 'Pending' },
        { studentId: 'S002', firstName: 'Jane', familyName: 'Smith', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S004', firstName: 'Emily', familyName: 'Johnson', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S007', firstName: 'David', familyName: 'Martinez', managedBy: 'Alice', status: 'Approved' },
        { studentId: 'S006', firstName: 'Sarah', familyName: 'Davis', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S003', firstName: 'Sam', familyName: 'Brown', managedBy: 'Alice', status: 'Rejected' },
        { studentId: 'S008', firstName: 'Laura', familyName: 'Garcia', managedBy: 'Bob', status: 'Rejected' },
        { studentId: 'S005', firstName: 'Michael', familyName: 'Williams', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S009', firstName: 'James', familyName: 'Rodriguez', managedBy: 'Alice', status: 'Pending' },
        { studentId: 'S011', firstName: 'John', familyName: 'Doe', managedBy: 'Alice', status: 'Pending' },
        { studentId: 'S012', firstName: 'Jane', familyName: 'Smith', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S010', firstName: 'Sophia', familyName: 'Wilson', managedBy: 'Charlie', status: 'Approved' },
        { studentId: 'S015', firstName: 'Michael', familyName: 'Williams', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S013', firstName: 'Sam', familyName: 'Brown', managedBy: 'Alice', status: 'Rejected' },
        { studentId: 'S016', firstName: 'Sarah', familyName: 'Davis', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S017', firstName: 'David', familyName: 'Martinez', managedBy: 'Alice', status: 'Approved' },
        { studentId: 'S019', firstName: 'James', familyName: 'Rodriguez', managedBy: 'Alice', status: 'Pending' },
        { studentId: 'S014', firstName: 'Emily', familyName: 'Johnson', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S018', firstName: 'Laura', familyName: 'Garcia', managedBy: 'Bob', status: 'Rejected' },
        { studentId: 'S020', firstName: 'Sophia', familyName: 'Wilson', managedBy: 'Charlie', status: 'Approved' }
    ];

    // Sample data for "Agency Applicants"
    const agencyApplicants = [
        { studentId: 'S012', firstName: 'Jane', familyName: 'Smith', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S010', firstName: 'Sophia', familyName: 'Wilson', managedBy: 'Charlie', status: 'Approved' },
        { studentId: 'S015', firstName: 'Michael', familyName: 'Williams', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S013', firstName: 'Sam', familyName: 'Brown', managedBy: 'Alice', status: 'Rejected' },
        { studentId: 'S016', firstName: 'Sarah', familyName: 'Davis', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S017', firstName: 'David', familyName: 'Martinez', managedBy: 'Alice', status: 'Approved' },
        { studentId: 'S019', firstName: 'James', familyName: 'Rodriguez', managedBy: 'Alice', status: 'Pending' },
        { studentId: 'S014', firstName: 'Emily', familyName: 'Johnson', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S018', firstName: 'Laura', familyName: 'Garcia', managedBy: 'Bob', status: 'Rejected' },
        { studentId: 'S020', firstName: 'Sophia', familyName: 'Wilson', managedBy: 'Charlie', status: 'Approved' },
        { studentId: 'S021', firstName: 'Alice', familyName: 'Taylor', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S022', firstName: 'Bob', familyName: 'Wilson', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S003', firstName: 'Sam', familyName: 'Brown', managedBy: 'Alice', status: 'Rejected' },
        { studentId: 'S008', firstName: 'Laura', familyName: 'Garcia', managedBy: 'Bob', status: 'Rejected' },
        { studentId: 'S005', firstName: 'Michael', familyName: 'Williams', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S009', firstName: 'James', familyName: 'Rodriguez', managedBy: 'Alice', status: 'Pending' },
        { studentId: 'S011', firstName: 'John', familyName: 'Doe', managedBy: 'Alice', status: 'Pending' },
        { studentId: 'S012', firstName: 'Jane', familyName: 'Smith', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S010', firstName: 'Sophia', familyName: 'Wilson', managedBy: 'Charlie', status: 'Approved' },
        { studentId: 'S015', firstName: 'Michael', familyName: 'Williams', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S013', firstName: 'Sam', familyName: 'Brown', managedBy: 'Alice', status: 'Rejected' },
        { studentId: 'S020', firstName: 'Sophia', familyName: 'Wilson', managedBy: 'Charlie', status: 'Approved' },
        { studentId: 'S021', firstName: 'Alice', familyName: 'Taylor', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S022', firstName: 'Bob', familyName: 'Wilson', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S003', firstName: 'Sam', familyName: 'Brown', managedBy: 'Alice', status: 'Rejected' },
        { studentId: 'S008', firstName: 'Laura', familyName: 'Garcia', managedBy: 'Bob', status: 'Rejected' },
        { studentId: 'S005', firstName: 'Michael', familyName: 'Williams', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S009', firstName: 'James', familyName: 'Rodriguez', managedBy: 'Alice', status: 'Pending' },
        { studentId: 'S011', firstName: 'John', familyName: 'Doe', managedBy: 'Alice', status: 'Pending' },
        { studentId: 'S012', firstName: 'Jane', familyName: 'Smith', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S010', firstName: 'Sophia', familyName: 'Wilson', managedBy: 'Charlie', status: 'Approved' },
        { studentId: 'S015', firstName: 'Michael', familyName: 'Williams', managedBy: 'Bob', status: 'Approved' },
        { studentId: 'S013', firstName: 'Sam', familyName: 'Brown', managedBy: 'Alice', status: 'Rejected' },
        { studentId: 'S016', firstName: 'Sarah', familyName: 'Davis', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S017', firstName: 'David', familyName: 'Martinez', managedBy: 'Alice', status: 'Approved' },
        { studentId: 'S019', firstName: 'James', familyName: 'Rodriguez', managedBy: 'Alice', status: 'Pending' },
        { studentId: 'S014', firstName: 'Emily', familyName: 'Johnson', managedBy: 'Charlie', status: 'Pending' },
        { studentId: 'S018', firstName: 'Laura', familyName: 'Garcia', managedBy: 'Bob', status: 'Rejected' },
        { studentId: 'S020', firstName: 'Sophia', familyName: 'Wilson', managedBy: 'Charlie', status: 'Approved' }
    ];

 
    const items = activeTab === 'my-applicants' ? myApplicants : agencyApplicants;

    const totalItems = items.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const displayedItems = items.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1); 
    };

    return (
        <div className='details-wrapper'>
            <div className='heading'>
                <h4>Applicants</h4>
            </div>
            <div className='table-container'>
                <div className='search-box-container'>
                    <div className="search-box">
                        <input type="text" placeholder="Search..." />
                        <button type="button"><FaSearch /></button>
                    </div>
                </div>
                <div className='toggle-container'>
                    <div className='toggle-tabs'>
                        <ul>
                            <li
                                className={activeTab === 'my-applicants' ? 'active' : ''}
                                onClick={() => handleTabClick('my-applicants')}
                            >
                                My Applicants
                            </li>
                            <li
                                className={activeTab === 'agency-applicants' ? 'active' : ''}
                                onClick={() => handleTabClick('agency-applicants')}
                            >
                                Agency Applicants
                            </li>
                        </ul>
                    </div>
                    <div className='toggle-data'>
                        <div className='table-responsive'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Student ID</th>
                                        <th>First Name</th>
                                        <th>Family Name</th>
                                        <th>Managed By</th>
                                        <th>Application Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody id="content">
                                    {displayedItems.map((item, index) => (
                                        <tr key={index} className="item">
                                            <td>{item.studentId}</td>
                                            <td>{item.firstName}</td>
                                            <td>{item.familyName}</td>
                                            <td>{item.managedBy}</td>
                                            <td>{item.status}</td>
                                            <td><button>View</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination-container">
                            {totalItems > 0 && (
                                <div className='page-numbers'>
                                    <button className="pagination-button" onClick={handlePrevClick} disabled={currentPage === 1} >
                                        <FaAngleLeft />
                                    </button>
                                    <span className="page-info">
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <button className="pagination-button" onClick={handleNextClick} disabled={currentPage === totalPages} >
                                        <FaAngleRight />
                                    </button>
                                </div>
                            )}
                            <div className='total-items'>
                                <span className="page-info">
                                    Total Items: {totalItems}
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Applicants;
